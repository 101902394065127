module.exports = [
  {
    label: 'English GB',
    default: true,
    path: 'en',
    locale: 'en-gb'
  },
  {
    label: 'English US',
    default: false,
    path: 'en-us',
    locale: 'en-us'
  },
  {
    label: 'English EU',
    default: false,
    path: 'en-eu',
    locale: 'en-eu'
  }
];
