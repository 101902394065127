import { useCallback } from 'react';
import { CountryCode } from '@dayetopia/types';
import { useLocation } from '@reach/router';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { findLocaleByLocaleString, getLocaleByCountryCode, removeLocalesFromPath } from '@utils/language';

const useLocaleNavigation = () => {
  const { changeLanguage } = useI18next();
  const location = useLocation();

  const navigateToLocaleByCountryCode = useCallback(
    (countryCode: CountryCode) => {
      const newLocaleString = getLocaleByCountryCode(countryCode);

      const newLocale = findLocaleByLocaleString(newLocaleString);

      if (newLocale?.path) {
        changeLanguage(newLocale?.path, removeLocalesFromPath(location.pathname), { replace: true });
      }
    },
    [location.pathname]
  );

  return { navigateToLocaleByCountryCode };
};

export default useLocaleNavigation;
