import React, { useContext } from 'react';
import { Link, I18nextContext } from 'gatsby-plugin-react-i18next';
import { Context } from '@components/Context';
import { LinkTranslatedProps } from '@contracts';
import { getLocaleUrlForPath, isInternalLink, removeLanguageIfInPath, getLocalUrlIfLiveOneUsed } from '@utils/language';

/**
 * This component is used to translate and handle internal and external links throughout the app.
 * It determines the type of link and renders a Gatsby Link or a standard anchor tag appropriately.
 * If `targetBlank` is true, or target='_blank' attribute is passed, the link will open in a new
 * tab with secure attributes.
 *
 * @param {LinkTranslatedProps} props The props for the LinkTranslated component.
 * @returns {JSX.Element} The translated link as a Gatsby Link or an anchor tag.
 */
const LinkTranslated = React.forwardRef<HTMLAnchorElement, LinkTranslatedProps>(
  ({ to, href, hrefCountry, language, targetBlank, preventDefault, children, ...rest }, ref) => {
    const context = useContext(I18nextContext);
    const {
      state: { country }
    } = useContext(Context);
    const { defaultLanguage } = context;
    const normalisedTo = getLocalUrlIfLiveOneUsed(to || href || '#');
    const isInternal = isInternalLink(normalisedTo);
    const currentLanguage = language || context.language;
    const finalAttributes = { ...rest };
    const hrefLang = `${currentLanguage}-${hrefCountry || country}`;

    if (targetBlank) {
      finalAttributes.target = '_blank';
    }

    /**
     * Automatically adds 'rel="noopener noreferrer"' when 'target="_blank"' is used.
     * This is important for security when opening links in a new tab, as it prevents
     * the newly opened page from accessing the 'window.opener' property. This attribute
     * is especially relevant for external links but is handled here for any link with
     * 'target="_blank"' for consistency and security best practices.
     */
    if (finalAttributes.target === '_blank') {
      finalAttributes.rel = 'noopener noreferrer';
    }

    /**
     * When `targetBlank` is true for internal links, it's a known issue in Gatsby that the Link component
     * doesn't support target="_blank" out of the box, hence the use of a regular anchor tag in this case.
     * Reference: [Gatsby Issue #13825](https://github.com/gatsbyjs/gatsby/issues/13825)
     */
    return isInternal && finalAttributes.target !== '_blank' && !language ? (
      <Link
        ref={ref}
        to={removeLanguageIfInPath(normalisedTo, defaultLanguage)}
        hrefLang={hrefLang}
        onClick={(e) => {
          if (preventDefault) {
            e.preventDefault();
          }
        }}
        {...finalAttributes}
      >
        {children}
      </Link>
    ) : (
      <a
        ref={ref}
        href={getLocaleUrlForPath(language ? removeLanguageIfInPath(normalisedTo, defaultLanguage) : normalisedTo, currentLanguage)}
        hrefLang={hrefLang}
        onClick={(e) => {
          if (preventDefault) {
            e.preventDefault();
          }
        }}
        {...finalAttributes}
      >
        {children}
      </a>
    );
  }
);

LinkTranslated.displayName = 'LinkTranslated';

export default LinkTranslated;
