/**
 * This object defines criteria for ignoring specific console warnings and errors
 * during the development process. The purpose is to reduce noise from known issues
 * that are not immediately actionable, allowing developers to focus on more critical
 * tasks. However, these warnings and errors should be addressed and resolved in the
 * future to ensure code quality and maintainability.
 */
const consoleIgnoreCriteria = {
  warn: [],
  error: ['Support for defaultProps will be removed from function components']
};

export default consoleIgnoreCriteria;
